import { computed } from '@vue/composition-api';

import { useEditBlockSlideshow } from '@/components/builder-controls/edit-block-slideshow/use/useEditBlockSlideshow';

export const useEditBlockSlideshowSlideBackground = (props, context) => {
	const { $store } = context.root;
	const {
		websiteBlocks,
		currentSlideshowSlideId,
	} = useEditBlockSlideshow(props, context);

	const currentSlideshowSlideBackground = computed(
		() => websiteBlocks.value[currentSlideshowSlideId.value].background ?? {
			color: '#ffffff',
			current: 'color',
		},
	);

	const currentSlideshowSlideBackgroundAttachment = computed(
		() => websiteBlocks.value[currentSlideshowSlideId.value].styles?.attachment ?? 'unset',
	);

	const setSlideBackground = (background) => {
		$store.commit('setBlockData', {
			blockId: currentSlideshowSlideId.value,
			data: { background },
		});
	};

	const setSlideBackgroundAttachment = (value) => {
		$store.commit('setBlockData', {
			blockId: currentSlideshowSlideId.value,
			data: { settings: { styles: { attachment: value } } },
		});
	};

	return {
		currentSlideshowSlideBackground,
		currentSlideshowSlideBackgroundAttachment,
		setSlideBackground,
		setSlideBackgroundAttachment,
	};
};

<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.editSlideshow.background.title')"
		@close="$emit('close')"
	>
		<ZyroBackground
			:background-object="currentSlideshowSlideBackground"
			@update:backgroundObject="setSlideBackground"
		/>
		<ZyroFieldToggle
			v-if="currentSlideshowSlideBackground.current === 'image'"
			id="backgroundAttachment"
			:label="$t('common.fixedBackground')"
			:checked="currentSlideshowSlideBackgroundAttachment === 'fixed'"
			@input="setSlideBackgroundAttachment($event.target.checked ? 'fixed' : 'unset')"
		/>
	</ZyroPopupCard>
</template>

<script>
import { useEditBlockSlideshowSlideBackground } from '@/components/builder-controls/edit-block-slideshow/use/useEditBlockSlideshowSlideBackground';

export default {
	setup(props, context) {
		const {
			currentSlideshowSlideBackground,
			currentSlideshowSlideBackgroundAttachment,
			setSlideBackground,
			setSlideBackgroundAttachment,
		} = useEditBlockSlideshowSlideBackground(props, context);

		return {
			currentSlideshowSlideBackground,
			currentSlideshowSlideBackgroundAttachment,
			setSlideBackground,
			setSlideBackgroundAttachment,
		};
	},
};
</script>
